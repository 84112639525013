import { createRouter, createWebHistory } from "vue-router";

const routes = [
  //Landing
  {
    path: "/",
    component: () => import("../components/layouts/main.layout.vue"),
    children: [
      {
        path: "",
        name: "landing",
        redirect: { name: "home" },
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../pages/index.page.vue"),
      },

      // {
      //   path: "",
      //   name: 'landing',
      //   redirect: { name: 'home' },
      // },
      // {
      //   name: 'home',
      //   path: "/home",
      //   component: () => import('../pages/index.page.vue'),
      // },
    ],
  },
  //Portal

  {
    path: "/pages",
    component: () => import("../components/layouts/other.layout.vue"),
    children: [
      {
        path: "/pages/privacy",
        name: "privacy",
        component: () => import("../pages/privacy.page.vue"),
      },

      {
        path: "/pages/terms",
        name: "terms",
        component: () => import("../pages/terms.page.vue"),
      },

      {
        path: "/pages/who",
        name: "who",
        component: () => import("../pages/who-we-are.vue"),
      },

      {
        path: "/pages/catalogue",
        name: "catalogue",
        component: () => import("../pages/catalogue.page.vue"),
      },

      {
        path: "/pages/catalogue/search",
        name: "catalogue_search-page",
        component: () => import("../pages/catalogue.search.vue"),
      },

      {
        path: "/pages/catalogue/providers",
        name: "catalogue_providers-page",
        component: () => import("../pages/catalogue.providers.vue"),
      },

      {
        path: "/pages/catalogue/providers/:id",
        name: "catalogue_providers-page-id",
        component: () => import("../pages/catalogue.providers-id.vue"),
      },

      {
        path: "/pages/catalogue/providers/type/:id",
        name: "catalogue_providers-type-page-id",
        component: () => import("../pages/catalogue.providers-type-id.vue"),
      },


      {
        path: "/pages/catalogue/promotions",
        name: "catalogue_promotions-page",
        component: () => import("../pages/catalogue.promotions.vue"),
      },

      {
        path: "/pages/catalogue/promotions/:id",
        name: "catalogue_promotions-page-id",
        component: () => import("../pages/catalogue.promotions-id.vue"),
      },

      {
        path: "/pages/catalogue/search/:id",
        name: "catalogue_search-page-id",
        component: () => import("../pages/catalogue.search-id.vue"),
      },

      {
        path: "/pages/book/:id",
        name: "bookings",
        component: () => import("../pages/booking.page.vue"),
      },
    ],
  },

  {
    path: "/portal",
    component: () => import("../components/layouts/plain.layout.vue"),
    children: [
      {
        path: "",
        name: "portal-home",
        redirect: { name: "portal-signin" },
      },
      {
        name: "portal-signin",
        path: "/portal/signin",
        component: () => import("../pages/portal/signin.page.vue"),
      },
      {
        name: "portal-signup",
        path: "/portal/signup",
        component: () => import("../pages/portal/signup.page.vue"),
      },
      {
        name: "portal-forgot-password",
        path: "/portal/forgot-password",
        component: () => import("../pages/portal/forgot-password.page.vue"),
      },
    ],
  },

  //bookings
  {
    path: "/user",
    name: "user",
    component: () => import("../components/layouts/booking.layout.vue"),
    children: [
      //Dashboard
      {
        path: "",
        name: "user-home",
        redirect: { name: "user-bookings-history" },
      },
      {
        path: "/user/bookings/:id",
        name: "user-bookings-history",
        component: () => import("../pages/bookings-history.page"),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem("RLE"));
      if (role != null && typeof role.name != "undefined") {
        if (role.name == "user") {
          next();
        } else {
          next({ name: "portal-signin" });
        }
      } else {
        next({ name: "portal-signin" });
      }
    },
  },
  //Admin
  {
    path: "/admin",
    name: "admin",
    component: () => import("../components/layouts/admin.layout.vue"),
    children: [
      //Dashboard
      {
        path: "",
        name: "admin-home",
        redirect: { name: "admin-dashboard" },
      },
      {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        component: () => import("../pages/admin/dashboard/index.page.vue"),
      },
      //Users
      {
        path: "/admin/users",
        name: "admin-users",
        component: () => import("../pages/admin/users/index.page.vue"),
      },
      {
        path: "/admin/users/manage/:id",
        name: "admin-manage-user",
        component: () => import("../pages/admin/users/manage.page.vue"),
      },

      {
        path: "/admin/catalogue",
        name: "admin-catalogue",
        component: () => import("../pages/admin/catalogue/index.page.vue"),
      },
      {
        path: "/admin/catalogue/manage/:id",
        name: "admin-manage-catalogue",
        component: () => import("../pages/admin/catalogue/manage.page.vue"),
      },

      {
        path: "/admin/bookings",
        name: "admin-bookings",
        component: () => import("../pages/admin/bookings/index.page.vue"),
      },

      {
        path: "/admin/feedback",
        name: "admin-feedback",
        component: () => import("../pages/admin/feedback/index.page.vue"),
      },
      {
        path: "/admin/bookings/manage/:id",
        name: "admin-manage-bookings",
        component: () => import("../pages/admin/bookings/manage.page.vue"),
      },

      {
        path: "/admin/help",
        name: "help",
        component: () => import("../components/layouts/help-center.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem("RLE"));
      if (role != null && typeof role.name != "undefined") {
        if (role.name == "admin") {
          next();
        } else {
          next({ name: "portal-signin" });
        }
      } else {
        next({ name: "portal-signin" });
      }
    },
  },

  {
    path: "/provider",
    name: "provider",
    component: () => import("../components/layouts/provider.layout.vue"),
    children: [
      //Dashboard
      {
        path: "",
        name: "provider-home",
        redirect: { name: "provider-dashboard" },
      },
      {
        path: "/provider/dashboard",
        name: "provider-dashboard",
        component: () => import("../pages/provider/dashboard/index.page.vue"),
      },

      {
        path: "/provider/profile/manage/:id",
        name: "provider-manage-user",
        component: () => import("../pages/provider/users/manage.page.vue"),
      },

      {
        path: "/provider/catalogue",
        name: "provider-catalogue",
        component: () => import("../pages/provider/catalogue/index.page.vue"),
      },
      {
        path: "/provider/catalogue/manage/:id",
        name: "provider-manage-catalogue",
        component: () => import("../pages/provider/catalogue/manage.page.vue"),
      },


      {
        path: "/provider/promotions",
        name: "provider-promotions",
        component: () => import("../pages/provider/promotions/index.page.vue"),
      },
      {
        path: "/provider/promotions/manage/:id",
        name: "provider-manage-promotions",
        component: () => import("../pages/provider/promotions/manage.page.vue"),
      },
      
      {
        path: "/provider/faqs",
        name: "provider-faqs",
        component: () => import("../pages/provider/faqs/index.page.vue"),
      },
      {
        path: "/provider/faqs/manage/:id",
        name: "provider-manage-faqs",
        component: () => import("../pages/provider/faqs/manage.page.vue"),
      },

      {
        path: "/provider/enquiries",
        name: "provider-enquiries",
        component: () => import("../pages/provider/enquiries/index.page.vue"),
      },


      {
        path: "/provider/appointments",
        name: "provider-appointments",
        component: () => import("../pages/provider/events/index.page.vue"),
      },
      {
        path: "/provider/enquiries/manage/:id",
        name: "provider-manage-enquiries",
        component: () => import("../pages/provider/enquiries/manage.page.vue"),
      },

      {
        path: "/provider/bookings/manage/:id",
        name: "provider-manage-bookings",
        component: () => import("../pages/provider/enquiries/manage-booking.page.vue"),
      },

      {
        path: "/provider/help",
        name: "help",
        component: () => import("../components/layouts/help-center.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem("RLE"));
      if (role != null && typeof role.name != "undefined") {
        if (role.name == "provider") {
          next();
        } else {
          next({ name: "portal-signin" });
        }
      } else {
        next({ name: "portal-signin" });
      }
    },
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/Errors/404.error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
